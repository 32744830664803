.container {
  width: '100%';
  height: 15.3125rem;
  position: absolute;
  bottom: 18.75rem;
  left: 0;
  overflow: hidden;
}
.background {
  width: 100%;
  height: 15.3125rem;
}

.foregroundWrapper {
  position: absolute;
  left: 0;
  top: 0;
  height: 15.3125rem;
  overflow: hidden;
}
.foreground {
  position: absolute;
  left: 0;
  top: 0;
  height: 15.3125rem;
  object-fit: cover;
  object-position: left;
}
