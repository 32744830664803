.container {
  flex-shrink: 1;
  flex-direction: row;
  border: 0.5625rem solid #a1a1a1;
  height: 7.625rem;
  padding: 0 3.75rem 0 5.625rem;
  margin-right: 4.0625rem;
}
.containerAr {
  flex-shrink: 1;
  flex-direction: row;
  border: 0.5625rem solid #a1a1a1;
  height: 7.625rem;
  padding: 0 3.75rem 0 5.625rem;
  margin-right: 4.0625rem;
  direction: rtl;
}
.text {
  font-size: 3rem;
  color: #a1a1a1;
  padding-left: 3.75rem;
}
.textAr {
  font-size: 3rem;
  color: #a1a1a1;
  padding-right: 3.75rem;
}
.icon {
  position: relative;
  width: 5.375rem;
  height: 7.5625rem;
  bottom: 1.375rem;
  left: -0.625rem;
  background-color: #002929;
}
