.container {
  flex-shrink: 1;
  flex-direction: row;
  border: 0.5625rem solid #3F965E;
  height: 7.625rem;
  padding: 0 3.75rem 0 5.625rem;
  margin-right: 8.75rem;
}
.containerAr {
  flex-shrink: 1;
  flex-direction: row;
  border: 0.5625rem solid #3F965E;
  height: 7.625rem;
  padding: 0 3.75rem 0 5.625rem;
  margin-right: 4.0625rem;
  direction: rtl;
}
.text {
  font-size: 3rem;
  color: #3F965E;
  padding-left: 3.75rem;
}
.textAr {
  font-size: 3rem;
  color: #3F965E;
  padding-right: 3.75rem;
}
.icon {
  width: 5.625rem;
  height: 5.625rem;
}
.iconAr {
  width: 5.625rem;
  height: 5.625rem;
  transform: rotate(180deg);
}
