.container {
  flex: 1;
  padding-bottom: 0.625rem;
  padding-left: 1.5625rem;
  padding-right: 1.5625rem;
  margin-left: 2rem;
  margin-right: 2rem;
  margin-bottom: 2rem;
  padding-top: 0.625rem;
  margin-top: 25.875rem;
  height: 16.3125rem;
  background: #3F965E;
}
.containerCorrect {
  flex: 1;
  padding-bottom: 0.625rem;
  padding-left: 1.5625rem;
  padding-right: 1.5625rem;
  margin-left: 2rem;
  margin-right: 2rem;
  margin-bottom: 2rem;
  padding-top: 0.625rem;
  margin-top: 0;
  background: #BFA74A;
  height: 42.1875rem;
  justify-content: space-between;
}
.containerIncorrect {
  flex: 1;
  padding-bottom: 0.625rem;
  padding-left: 1.5625rem;
  padding-right: 1.5625rem;
  margin-left: 2rem;
  margin-right: 2rem;
  margin-bottom: 2rem;
  padding-top: 0.625rem;
  margin-top: 0;
  background: #A1A1A1;
  height: 42.1875rem;
  justify-content: space-between;
}
.containerWithIcon {
  flex: 1;
  padding-bottom: 0.625rem;
  padding-left: 1.5625rem;
  padding-right: 1.5625rem;
  margin-left: 2rem;
  margin-right: 2rem;
  margin-bottom: 2rem;
  padding-top: 7.8125rem;
  margin-top: 0;
  height: 50rem;
  background: #3F965E;
  justify-content: space-between;
}
.containerCorrectWithIcon {
  flex: 1;
  padding-bottom: 0.625rem;
  padding-left: 1.5625rem;
  padding-right: 1.5625rem;
  margin-left: 2rem;
  margin-right: 2rem;
  margin-bottom: 2rem;
  padding-top: 7.8125rem;
  margin-top: 0;
  background: #BFA74A;
  height: 50rem;
  justify-content: space-between;
}
.containerIncorrectWithIcon {
  flex: 1;
  padding-bottom: 0.625rem;
  padding-left: 1.5625rem;
  padding-right: 1.5625rem;
  margin-left: 2rem;
  margin-right: 2rem;
  margin-bottom: 2rem;
  padding-top: 7.8125rem;
  margin-top: 0;
  background: #A1A1A1;
  height: 50rem;
  justify-content: space-between;
}

.answerIconWrapper {
  width: 23.125rem;
  height: 23.125rem;
  /* resizeMode: 'contain', */
  margin-top: 3.125rem;
}
.answerIcon {
  width: 100%;
  height: auto;
}


.text {
  font-size: 3rem;
  text-align: center;
  height: 15.0625rem;
}


.feedback {
  flex-direction: row;
  width: 40.625rem;
  margin: 3.125rem 0;
}
.feedbackTextLarge {
  flex: 1;
  font-size: 5.3125rem;
}
.feedbackTextSmall {
  flex: 1;
  font-size: 2.5rem;
}
.feedbackTextCorrect {
  flex: 1;
}
.feedbackTextIncorrect {
  flex: 1;
  color: #002929;
}
.feedbackIcon {
  width: 17.875rem;
  height: 17.875rem;
  margin-right: 2.1875rem;
}
.feedbackIconAr {
  width: 17.875rem;
  height: 17.875rem;
  margin-left: 2.1875rem;
}
