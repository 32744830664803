.background {
  position: absolute;
  top: 0;
  width: 100%;
  height: 74.768519%;
  z-index: -1;
}

.container {
  align-items: center;
  justify-content: center;
  /* padding: 16rem 0 34.0625rem 0; */
  padding: 16rem 0 0;
  width: 100%;
  height: 74.768519%;
}

.textContainer {
  flex: 2;
  align-items: center;
  justify-content: center;
}

.heading {
  font-size: 11.875rem;
}

.subheading {
  font-size: 4.8125rem;
}

.buttonsContainer {
  flex: 3;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.buttonText {
  display: flex;
  width: 100%;
  font-size: 3.4375rem;
}
.buttonTextAr {
  display: flex;
  width: 100%;
  font-size: 3.4375rem;
  direction: rtl;
}

.button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 46.875rem;
  height: 20.8125rem;
  padding: 0 6.125rem;
  margin: 0 5.0625rem;
  background: #3F965E;
}
