@font-face {
  font-family: "Inter";
  src: url("/public/fonts/Inter-VariableFont_opsz\,wght.ttf");
}

html {
  font-size: 100%;
}


body {
  margin: 0;
  font-family: Inter;
  font-weight: bold;
  font-size: 16px;
  color: #fff;
  background: #002929;
  line-height: normal;
}

div {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

* {
  box-sizing: border-box;
}
