.container {
  position: relative;
  width: 110.5625rem;
  height: 72.625rem;
  box-shadow: 10px 10px 25px 0px rgba(0, 0, 0, 0.25);
  border: 3.125rem solid #3f965e;
}
.containerCorrect {
  position: relative;
  width: 110.5625rem;
  height: 72.625rem;
  box-shadow: 10px 10px 25px 0px rgba(0, 0, 0, 0.25);
  border: 3.125rem solid #BFA74A;
}
.containerIncorrect {
  position: relative;
  width: 110.5625rem;
  height: 72.625rem;
  box-shadow: 10px 10px 25px 0px rgba(0, 0, 0, 0.25);
  border: 3.125rem solid #A1A1A1;
}

.answerImage {
  width: 104.3125rem;
  height: 66.375rem;
}

.feedbackCorrect {
  width: 100%;
  height: 23.4375rem;
  padding-top: 3.125rem;
  position: absolute;
  bottom: 0;
  z-index: 1;
  flex-direction: row;
  background-color: #BFA74A;
}
.feedbackIncorrect {
  width: 100%;
  height: 23.4375rem;
  padding-top: 3.125rem;
  position: absolute;
  bottom: 0;
  z-index: 1;
  flex-direction: row;
  background-color: #A1A1A1;
}
.feedbackTextLarge {
  flex: 1;
  font-size: 5.3125rem;
  min-width: 21rem;
}
.feedbackTextSmall {
  flex: 1;
  font-size: 2.5rem;
  min-width: 21rem;
}
.feedbackTextCorrect {
  flex: 0 1;
}
.feedbackTextIncorrect {
  flex: 0 1;
  color: #002929;
}
.feedbackIcon {
  width: 17.875rem;
  height: 17.875rem;
  margin-right: 2.1875rem;
}
.feedbackIconAr {
  width: 17.875rem;
  height: 17.875rem;
  margin-left: 2.1875rem;
}
