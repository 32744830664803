.container {
  flex-direction: row;
  justify-content: space-between;
  border-top: 0.375rem solid #204E4C;
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 0 4.6875rem;
  height: 18.75rem;
}

.containerWithProgressBar {
  flex-direction: row;
  justify-content: space-between;
  border-top: 0.375rem solid #204E4C;
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 15.125rem 4.6875rem 0 4.6875rem;
  height: 33.875rem;
}

.footerLogoWrapper {
  width: 27.625rem;
  height: 10.875rem;
}
.footerLogo {
  width: 27.625rem;
  height: 10.875rem;
}
.buttonsContainer {
  flex-shrink: 1;
  flex-direction: row
}
