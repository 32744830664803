.container {
  flex-direction: row;
  width: 100vw;
  height: calc(100vh - 18.75rem);
  /* margin-bottom: 18.75rem; */
  /* background-color: #002929; */
}
.containerAltColor {
  flex-direction: row;
  width: 100vw;
  height: calc(100vh - 18.75rem);
  /* padding-bottom: 18.75rem; */
  background-color: #DEE3E3
}
.textContainer {
  flex: 1;
  margin: 0 10.75rem;
  width: 81.25rem;
}
.heading {
  font-size: 6.25rem;
  padding-bottom: 3.4375rem;
  margin-bottom: 3.4375rem;
  border-bottom: 0.375rem solid #a1a1a1;
  width: 100%;
}
.headingAltColor {
  font-size: 6.25rem;
  padding-bottom: 3.4375rem;
  margin-bottom: 3.4375rem;
  border-bottom: 0.375rem solid #a1a1a1;
  width: 100%;
  color: #3F965E;
}
.paragraph {
  font-weight: normal;
  font-size: 4.0625rem;
  width: 100%;
}
.paragraphAltColor {
  font-weight: normal;
  font-size: 4.0625rem;
  width: 100%;
  color: #002929;
}
.progressContainer {
  width: 16.8125rem;
  height: 100%;
  position: relative;
}
.imageOrVideo {
  position: relative;
  width: 50%;
  height: 100%;
}
.imageOrVideoActual {
  width: 100%;
  height: 100%;
}
.nextButtonContentLeft {
  position: absolute;
  top: 51.875rem;
  left: 9.375rem;
}
.nextButtonContentRight {
  position: absolute;
  top: 51.875rem;
  right: 9.375rem;
}
.nextIcon {
  width: 17.4375rem;
  height: 17.4375rem;
}
.nextIconAr {
  width: 17.4375rem;
  height: 17.4375rem;
  transform: rotate(180deg);
}
