.container {
  width: 16.8125rem;
  height: 116.25rem;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
}
.background {
  width: 100%;
  height: 100%;
}

.foregroundWrapper {
  position: absolute;
  left: 0;
  top: 0;
  width: 16.8125rem;
  overflow: hidden;
}
.foreground {
  position: absolute;
  left: 0;
  top: 0;
  width: 16.8125rem;
  object-fit: cover;
  object-position: top;
}
