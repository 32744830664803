.background {
  position: absolute;
  top: 0;
  width: 100%;
  height: 74.768519%;
  z-index: -1;
}

.container {
  align-items: center;
  justify-content: center;
  padding: 16rem 0 0;
  width: 100%;
  height: 74.768519%;
}

.textContainer {
  flex: 2;
  align-items: center;
  justify-content: center;
}

.heading {
  font-size: 21.875rem;
}

.buttonsContainer {
  flex: 3;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.buttonText {
  display: flex;
  margin: 0 2.5625rem;
  font-size: 3.4375rem;
}

.button {
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 46.875rem;
  height: 20.8125rem;
  padding: 0 6.125rem;
  margin: 0 5.0625rem;
  background: #3F965E;
}
.buttonAr {
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 46.875rem;
  height: 20.8125rem;
  padding: 0 6.125rem;
  margin: 0 5.0625rem;
  background: #3F965E;
  direction: rtl;
}

.beginIcon {
  width: 6.6875rem;
  height: 6.6875rem;
}
.beginIconAr {
  width: 6.6875rem;
  height: 6.6875rem;
  transform: rotate(180deg)
}
