.container {
  /* flex: 1; */
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 74.768519%;
}

.headingContainer {
  width: 100%;
  height: 20.9375rem;
}
.headingContainerAltColor {
  width: 100%;
  height: 20.9375rem;
  background: #DEE3E3;
}
.heading {
  font-size: 6.25rem;
  width: 100%;
  text-align: center;
  padding: 0 21.875rem;
}
.headingAltColor {
  font-size: 6.25rem;
  width: 100%;
  text-align: center;
  padding: 0 21.875rem;
  color: #002929
}

.mainContent {
  position: relative;
  flex: 1 0;
  width: 100%;
  height: 100%;
  justify-content: flex-end;
  padding-bottom: 4.0625rem;
}
.mainContentImages {
  flex: 1 0;
  width: 100%;
  height: 100%;
  justify-content: flex-end;
  padding-bottom: 4.0625rem;
  background-color: #ffffff;
}
.imageOrVideo {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}

.answers {
  flex-direction: row;
  justify-content: space-between;
  width: 85%;
}
.answersShort {
  flex-direction: row;
  justify-content: space-between;
  width: 45%;
}
.answersImages {
  flex-direction: row;
  justify-content: space-evenly;
  width: 100%;
}
