.container {
  width: 100%;
  height: 74.768519%;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-evenly;
  background-color: #D9D9D9;
  padding: 8.125rem 0 0;
}

.textContainer {
  justify-content: space-around;
  width: 112.5rem;
  height: 100%;
  padding-bottom: 6.25rem;
  align-items: flex-start;
}
.textContainerAr {
  justify-content: space-around;
  width: 112.5rem;
  height: 100%;
  padding-bottom: 6.25rem;
  align-items: flex-end;
  direction: rtl;
}

.heading {
  font-size: 21.875rem;
  color: #3F965E;
  line-height: 21.875rem;
  width: 100%;
}
.subheading {
  font-weight: 500;
  font-size: 6.25rem;
  color: #002929;
  line-height: 7.625rem;
  width: 100%;
}
.text {
  font-weight: 500;
  font-size: 0.83rem;
  color: #002929;
  line-height: 6.32875rem;
  width: 100%;
}

.buttonsContainer {
  align-items: flex-start;
  width: 100%;
}
.button {
  flex-direction: row;
  width: 56.25rem;
  height: 16.3125rem;
  background-color: #3F965E;
  margin-top: 3.125rem;
}
.buttonText {
  font-size: 3.4375rem;
  margin: 0 3.4375rem;
}
.beginIcon {
  width: 6.6875rem;
  height: 6.6875rem;
}
.beginIconAr {
  transform: rotate(180deg)
}

.badgeWrapper {
  width: 50rem;
  height: 100%;
}
.badge {
  width: 100%;
  height: auto;
}
